<template>
  <i :class="iconClass" :style="styles">
    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="10" viewBox="0 0 8 10"><path d="M6.933 4.2a1 1 0 0 1 0 1.6L1.6 9.8A1 1 0 0 1 0 9V1A1 1 0 0 1 1.6.2z"/></svg>
  </i>
</template>

<script>
import BaseIcon from './BaseIcon.vue';

export default {
  name: 'IconPlay',
  extends: BaseIcon,
  iconName: 'play',
};
</script>
