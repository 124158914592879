<template>
  <div>
    <img
      v-if="channel.logo"
      :alt="channel.name"
      class="border rounded bg-white"
      height="32"
      :src="prepareSrc(channel.logo)"
      width="32"
    >
    <img
      v-else
      :alt="channel.name"
      class="border rounded bg-white"
      :height="32"
      loading="lazy"
      src="@/assets/images/logo/channel-logo.svg"
      :width="32"
    >

    <strong class="px-1">
      <small>
        {{ channel.name }}
      </small>
    </strong>

    <icon-play-circle
      class="fill-primary d-none"
      :height="16"
      :width="16"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import IconPlayCircle from '~/components/icons/IconPlayCircle.vue';
import { prepareAssetPath } from '~/utils/common';

export default defineComponent({
  name: 'ChannelItem',

  components: {
    IconPlayCircle,
  },

  props: {
    channel: {
      required: true,
      type: Object,
    },
  },

  methods: {
    prepareSrc(path) {
      return prepareAssetPath(`/soccer/${path}`);
    },
  },
});
</script>
