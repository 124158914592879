import { addMinutes } from 'date-fns';
import { prepareAssetPath } from '~/utils/common';
import { TZ_MEXICO_CITY } from '~/config/global';

export function getJsonLdTeam(team, baseUrl) {
  const logoPath = prepareAssetPath(team.logo);

  return {
    '@type': 'SportsTeam',
    sport: 'Football',
    name: team.name,
    logo: `${baseUrl}${logoPath}`,
  };
}

export function getJsonLdLocation(match) {
  const { venue } = match;

  if (!venue) {
    return {
      '@type': 'Place',
      name: `Estadio ${match.home.name}`,
    };
  }

  return {
    '@type': 'Place',
    name: venue.name,
  };
}

export function getJsonLdMatchDescription(match) {
  const parts = [];

  const { competition, season, round } = match;

  if (competition) {
    parts.push(competition.name);
  }

  if (season && season.year) {
    parts.push(season.year);
  }

  if (round && round.number) {
    parts.push(round.number);
  }

  if (parts.length === 0) {
    return null;
  }

  return parts.join(' ');
}

export function getJsonLdMatch(match, { formatDate, resolveRoute, withOffer, $i18n }) {
  const description = getJsonLdMatchDescription(match);

  const {
    home,
    away,
    start_time,
    page,
  } = match;

  const startDateRaw = new Date(start_time);
  const startDate = formatDate(startDateRaw);
  const endDate = formatDate(addMinutes(startDateRaw, 105));
  const name = `${home.name} vs ${away.name}`;
  const { baseUrl, locale } = $i18n;

  let url = null;
  let image = null;

  if (page && page.slug) {
    const { slug } = page;

    url = resolveRoute({
      name: 'match-slug',
      params: {
        slug,
      },
    });

    image = {
      '@type': 'ImageObject',
      width: 1200,
      height: 675,
      url: `${baseUrl}/images/${locale}/match/${slug}?timezone=${TZ_MEXICO_CITY}`,
    };
  }

  const broadcastOfEvent = {
    '@context': 'https://schema.org',
    '@type': 'SportsEvent',
    name,
    eventStatus: 'http://schema.org/EventScheduled',
    eventAttendanceMode: 'http://schema.org/EventScheduled',
    description,
    url,
    startDate,
    endDate,
    sport: 'Football',
    image,
    homeTeam: getJsonLdTeam(home, baseUrl),
    awayTeam: getJsonLdTeam(away, baseUrl),
    location: getJsonLdLocation(match),
  };

  if (withOffer) {
    broadcastOfEvent.offers = {
      '@type': 'Offer',
      availability: 'http://schema.org/OnlineOnly',
      price: 0.00,
      priceCurrency: 'EUR',
      validFrom: startDate,
      startDate,
      endDate,
      url,
    };
  }

  return {
    '@context': 'https://schema.org',
    '@type': 'BroadcastEvent',
    name,
    description,
    startDate,
    endDate,
    isLiveBroadcast: true,
    videoFormat: 'HD',
    broadcastOfEvent,
  };
}
