export const COLOR_PRIMARY = 'primary';
export const COLOR_SECONDARY = 'secondary';
export const COLOR_SUCCESS = 'success';
export const COLOR_DANGER = 'danger';
export const COLOR_WARNING = 'warning';
export const COLOR_INFO = 'info';
export const COLOR_LIGHT = 'light';
export const COLOR_DARK = 'dark';
export const COLOR_LINK = 'link';

export const SIZE_SM = 'sm';
export const SIZE_MD = 'md';
export const SIZE_LG = 'lg';

export const BUTTON_COLORS = [
  COLOR_PRIMARY,
  COLOR_SECONDARY,
  COLOR_SUCCESS,
  COLOR_DANGER,
  COLOR_WARNING,
  COLOR_INFO,
  COLOR_LIGHT,
  COLOR_DARK,
  COLOR_LINK,
];

export const BUTTON_SIZES = [
  SIZE_SM,
  SIZE_MD,
  SIZE_LG,
];

export const BADGE_COLORS = [
  COLOR_PRIMARY,
  COLOR_SECONDARY,
  COLOR_SUCCESS,
  COLOR_DANGER,
  COLOR_WARNING,
  COLOR_INFO,
  COLOR_LIGHT,
  COLOR_DARK,
];

export const LIST_GROUP_ITEM_COLORS = [
  COLOR_PRIMARY,
  COLOR_SECONDARY,
  COLOR_SUCCESS,
  COLOR_DANGER,
  COLOR_WARNING,
  COLOR_INFO,
  COLOR_LIGHT,
  COLOR_DARK,
];
