<template>
  <i :class="iconClass" :style="styles">
    <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512"><path d="M256 0C114.616 0 0 114.616 0 256s114.616 256 256 256c141.394 0 256-114.616 256-256S397.394 0 256 0m0 460.8c-112.927 0-204.8-91.873-204.8-204.8S143.073 51.2 256 51.2 460.8 143.073 460.8 256 368.927 460.8 256 460.8"/><path d="m349.112 238.08-124.15-71.68c-17.07-9.851-31.037-1.792-31.037 17.92v143.36c0 19.712 13.967 27.781 31.037 17.92l124.15-71.68c17.07-9.851 17.07-25.989 0-35.84"/></svg>
  </i>
</template>

<script>
import BaseIcon from './BaseIcon.vue';

export default {
  name: 'IconPlayCircle',
  extends: BaseIcon,
  iconName: 'play-circle',
};
</script>
